<template>
  <div class="main-box">
    <div style="height:60px"></div> <!-- 占位 -->
    <!-- 面包屑 -->
    <div class="menu-inner">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/study/study">学习中心</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="color: #15b7dd">考试报名</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="signup-panel">
      <h4 class="title">{{examName}}</h4>
      <div class="content-inner">
        <!-- 指南 -->
        <div class="child-item guide-item" v-show="guideData.length">
          <img class="title-icon" src="@/assets/image/study/20230516-111706.png" alt="">
          <div class="guide-ul" :class="{'guide-ul-hide' : !showGuide}">
            <div class="guide-li" v-for="(item, index) in guideData" :key="item.guideId">
              <div class="li-left">
                <i>{{index + 1}}</i>
                <span>{{item.name}}</span>
              </div>
              <div class="li-right">
                <p>{{item.context}}</p>
                <!-- <p><img src="@/assets/image/study/tips.png" alt="">注:考试前7日须完成所有课程及课后练习，否则将会被驳回考试申请。</p> -->
              </div>
            </div>
          </div>
          <div class="show-hide" v-if="!showGuide">
            <span @click="showGuide = true">展开 <img src="@/assets/image/study/20230516-111733.png" alt=""></span>
          </div>
        </div>
        <!-- 安排 -->
        <div class="child-item plan-item">
          <img class="title-icon" src="@/assets/image/study/20230516-111707.png" alt="">
          <!-- 考试安排模板1 -->
          <div class="template-1" v-if="type == 1">
            <!-- 分类tabs -->
            <div class="tabs">
              <p
                v-if="this.examList.length"
                class="tabItem"
                @click="tabIndex = 0"
                :class="{ active: 0 == tabIndex }"
              >
                最新
              </p>
              <p
                class="tabItem"
                @click="tabIndex = 1"
                :class="{ active: 1 == tabIndex }"
              >
                全年
              </p>
            </div>
            <!-- 最新计划 -->
            <div class="plan-inner" v-show="tabIndex == 0 && this.examList.length">
              <!-- 时间轴 -->
              <div class="scroll-time">
                <span :class="{'span-active' : signupId == item.signupId}" @click="onSelectTime(item)" v-for="item in examList" :key="item.signupId">{{item.examDate}}</span>
              </div>
              <!-- 报名列表和课程购买 -->
              <div class="div-flex">
                <a-spin class="sping" v-show="loading" size="large" />
                <div class="signup-list">
                  <div class="signup-item" :class="!isBuyCourse ? 'signup-item3' : 'signup-item4'" v-for="item in timeData" :key="item.timeId">
                    <p class="session"><img src="@/assets/image/study/20230516-111731.png" alt="">{{ item.timeName }} 场</p>
                    <p class="time">考试时间：{{item.examDate}}</p>
                    <p class="time">考试时长：{{ manageDuration(item.duration) }}</p>
                    <p class="all-btn-small disable" v-if="item.signFlag == 1">已报名</p>
                    <p class="all-btn-small disable" v-else-if="item.signupNum >= item.totalSignupNum">已报满</p>
                    <p class="all-btn-small" v-else @click="onEnroll(item)">报名考试</p>
                  </div>
                </div>
                <div class="course-shop" v-if="!isBuyCourse">
                  <img class="shop-icon" :src="(courseData && courseData.length > 1) ? shopIconMore : shopIconSingle" alt="">
                  <div class="product-info-list">
                    <div class="product-info-item" v-for="item in courseData" :key="item.courseId">
                      <div class="product-flex">
                        <img class="course-cover" :src="item.mobileListPicture" alt="">
                        <div>
                          <p class="course-name">{{item.courseName}}</p>
                          <span class="course-hour">{{item.categoryName}} | {{item.classHour}}课时</span>
                        </div>
                      </div>
                      <div class="buy-btn">
                        <span @click="courseDetail(item)" class="all-btn-small"><img src="@/assets/image/study/20230516-162618.png" alt=""> 立即购买</span>
                      </div>
                      <img class="connect-icon" src="@/assets/image/study/20240708-162618.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 全年计划 -->
            <div class="year-inner" v-show="tabIndex == 1">
              <img :src="yearDetail" alt="全年" title="全年">
            </div>
          </div>

          <!-- 考试安排模板2 -->
          <div class="template-2" v-if="type == 2">
            <div class="exam-plan-item" v-for="item in myExamList" :key="item.examineeId">
              <div class="head-exam-title">
                <p>
                  <img src="@/assets/image/study/20230516-111732.png" alt="">{{item.examDate}}
                  <span class="org" v-show="item.orgId"><img src="@/assets/image/study/20230525-01.png" alt="">机构报名</span>
                </p>
                <span class="open" @click="item.isShow = !item.isShow">{{item.isShow ? '收起' : '展开'}}<img :class="{'rote-img' : !item.isShow}" src="@/assets/image/study/20230516-111733.png" alt=""></span>
              </div>

              <div class="exam-status-div" v-show="item.isShow">
                <!-- 考试状态轴 -->
                <div class="time-axle">
                  <div class="axle-item">
                    <img src="@/assets/image/study/20230516-111734.png" alt="">
                    <p>报名信息：{{item.examDate}}</p>
                  </div>
                  <div class="axle-item">
                    <img src="@/assets/image/study/20230523-01.png" alt="">
                    <p>系统审核时间：{{item.advanceTime}}</p>
                  </div>
                  <div class="axle-item">
                    <img src="@/assets/image/study/20230523-02.png" alt="">
                    <p>审核状态：
                      <span :class="item.auditStatus == 2 ? 'order-pass' : item.auditStatus == 3 ? 'order-fail' :''">
                        {{item.auditStatustText}}
                      </span>
                    </p>
                    <p v-if="item.auditStatus == 3 && item.rejecReason" style="white-space: normal;min-width:150px">驳回原因：{{item.rejecReason}}</p>
                  </div>
                  <div class="axle-item" v-if="item.auditStatus == 2">
                    <img src="@/assets/image/study/20230523-03.png" alt="">
                    <p>考试结果：
                      <span :class="{
                        'order-pass': item.examStatus == 3,
                        'order-fail': item.examStatus == 4,
                      }">
                        {{ toolExamStatus(item.examStatus) }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="exam-time">
                  <span>考试时间：{{item.timeDate}}</span>
                  <i>|</i>
                  <span>考试时长：{{ manageDuration(item.duration) }}</span>
                </div>
                <div class="sign-btn">
                  <span v-if="item.auditStatus==1 || item.auditStatus==4" @click="toMessage(item,1)"><img src="@/assets/image/study/20230516-111737.png" alt="">{{item.auditStatus == 4 ? '完善信息' : '修改信息'}}</span>
                  <span v-if="item.auditStatus==2 && (item.examStatus== 1 || item.examStatus== 2)" @click="toMessage(item,2)"><img src="@/assets/image/study/20230516-111736.png" alt="">查看信息</span>
                  <span v-if="item.auditStatus == 3 || item.examStatus == 4 || item.auditStatus==0" @click="type = 1"><img src="@/assets/image/study/20230530-100605.png" alt="">重新报名</span>
                  <span v-if="item.auditStatus == 1 && !item.orgId && item.isHideTimes == 0" @click="toChangeExam(item.signupId, item.signupUserId)"><img src="@/assets/image/study/20230516-111735.png" alt="">更换场次</span>
                  <span v-if="item.examStatus == 3 && item.isConfirm == 0 && !item.orgId && item.userConfirmFlag" @click="toConfirm(item )"><img src="@/assets/image/study/20230530-093834.png" alt="">确认信息</span>
                  <span v-if="item.examStatus == 3 && item.isConfirm == 0 && item.orgId && item.mailingMode == 0" @click="toConfirmOk(item )"><img src="@/assets/image/study/20230530-093834.png" alt="">证件照确认</span>
                  <span v-if="item.examStatus == 3 && item.isConfirm == 1" @click="toConfirmDetail(item)"><img src="@/assets/image/study/20230530-100601.png" alt="">证书信息</span>
                  <span v-if="item.examStatus == 3 && item.orgId && item.mailingMode == 2"><img src="@/assets/image/study/20230530-151737.png" alt="">待机构确认</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 报名须知 -->
        <div class="child-item notice-item" >
          <h4 class="h4-title">考试须知</h4>
          <div v-html="appExamDesc"></div>
        </div>
      </div>
    </div>
    <a-modal
      :width="480"
      :visible="visible"
      :footer="false"
      :closable="false"
      @cancel="handleCancel"
    >
      <div class="modal_box">
        <h4 class="modal_h4">《{{examName}}》</h4>
        <div class="txt-inner" v-html="appSignupDesc">
          
        </div>
        <div class="sign-btn" @click="onMessagePage" :class="{'sign-btn-dis' : second > 0 }">
          我已阅读<i v-if="second > 0">（{{second}}s）</i>
        </div>
      </div>
    </a-modal>
    <!-- 考试更换场次弹窗 -->
    <a-modal
      :width="480"
      :visible="modifyExamShow"
      :footer="false"
      :closable="false"
      @cancel="handleCancel"
      class="exam-modal"
    >
      <div class="exam-modal-box">
        <h4>更换考试场次</h4>
        <div class="box">
          <p class="title">当前报名场次</p>
          <div class="time-inner">
            <p class="time"><img src="@/assets/image/study/20230516-111731.png" alt="">{{examObj.timeName}}场</p>
            <div class="line"><span class="time" style="margin-right:40px">考试时间：{{examObj.examDateTime}}</span><span class="time">考试时间：{{manageDuration(examObj.duration)}}</span></div>
          </div>
        </div>
        <div class="box">
          <p class="title">以下是可更换的报名场次</p>
          <!-- 可选场次 -->
          <div class="time-inner select-inner" @click="modifyTimeId = item.timeId" :class="{'select-active' : item.timeId == modifyTimeId}" v-for="item in examModifyList" :key="item.timeId">
            <p class="time">
              <img v-show="item.timeId != modifyTimeId" src="@/assets/image/study/20230516-111731.png" alt="">
              <img v-show="item.timeId == modifyTimeId" src="@/assets/image/study/20230516-111739.png" alt="">
              {{item.timeName}}场</p>
            <div class="line"><span class="time" style="margin-right:40px">考试时间：{{item.examDateTime}}</span><span class="time">考试时间：{{manageDuration(item.duration)}}</span></div>
          </div>
          <!-- 提交按钮 -->
          <div class="submit-btn">
            <span class="cancel-btn" @click="modifyExamShow = false">取消</span>
            <span class="all-btn-small" @click="changeSession">确认更换</span>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 未购课提示窗 -->
    <a-modal
      width="480px"
      :visible="isCourseTipsShow"
      :footer="false"
      @cancel="isCourseTipsShow = false"
    >
      <div class="modal-content">
        <p class="tips">
          <img
            src="@/assets/image/personalCenter/2022050913.png"
            alt=""
          />提示信息
        </p>
        <div class="content">
          仅限
          <span v-for="(item,index) in courseList" :key="'courseId' + item.courseId">
            <a @click="courseDetail(item)">《{{item.courseName}}》</a>
            <span v-if="index !== courseList.length - 1">或</span>
          </span>
          学员参加考试
        </div>
        <div class="btn-foot">
          <p class="all-btn-small" @click="isCourseTipsShow = false">我知道了</p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
    <!-- 考试报名弹窗 -->
    <TipsModal 
      :isCancelBtnShow="isCancelBtnShow"
      :ConfirmBtnText="ConfirmBtnText"
      :isPublicTipsShow="isPublicTipsShow" 
      :tipsText="tipsText"
      @setEvent="onCloseTipsMadol" />
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      visible:false,
      showGuide: false,
      tabIndex: 0,
      isBuyCourse: true,
      courseData: [], // 课程详情
      second: 3,
      loading: false,
      modifyExamShow: false, // 考试场次更换弹窗
      examObj: {},
      examModifyList:[], //可更换场次列表
      modifyTimeId:0,

      configId: 0, // 考试id
      timeId: 0, // 场次id
      signupId: 0, // 报名id
      auditTime: '', // 考试审核时间
      signupUserId: '', // 考生用户id
      guideData: [], // 指南列表
      type: null, // 1.报名考试  2.我的考试
      examList: [], // 考试报名时间
      timeData: [], // 考试报名场次

      yearDetail: [], // 全年
      appExamDesc: '', // 考试须知
      appSignupDesc: [], // 报名须知
      courseId: [], // 课程id
      examName: [], // 考试名称
      tipsText:'', // 考试报名弹窗文案
      isPublicTipsShow: false,
      isCourseTipsShow: false,
      ConfirmBtnText:'我知道了',
      isCancelBtnShow: false,
      myExamList: [], // 考试安排列表
      courseList:[],

      shopIconSingle: require('@/assets/image/study/20230516-160714.png'),
      shopIconMore: require('@/assets/image/study/20230516-160733.png'),
    }
  },
  // 事件处理器
  methods: {
    // 报考指南
    getGuide() {
      this.$ajax({
        url: "/hxclass-pc/exam/guide/list?configId=" + this.configId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.guideData = res.data;
          // 展开全部
          if (this.guideData.length <= 3) {
            this.showGuide = true;
          }
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 获取考试报名列表
    getExamList() {
      let userId = this.$store.state.userInfo.isLogin
        ? this.$store.state.userInfo.userId
        : "";
      this.$ajax({
        url: "/hxclass-pc/exam/signup/time/list?configId=" + this.configId + "&userId=" + userId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.forEach(element => {
            element.timeList.forEach(child => {
              child.signupId = element.signupId
            });
          });

          this.examList = res.data;
          if(!res.data.length){
            this.tabIndex = 1
            return
          }

          this.signupId = res.data[0].signupId
          this.auditTime = res.data[0].auditTime
          this.timeData = res.data[0].timeList
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 全年详情
    getYearDetail() {
      this.$ajax({
        url: "/hxclass-pc/exam/exam-config-info?configId=" + this.configId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.yearDetail = res.data.yearlyPlanPicture; //全年
          this.appExamDesc = res.data.appExamDesc; //考试须知
          this.appSignupDesc = res.data.appSignupDesc; //报名须知
          this.courseId = res.data.courseId //课程id
          this.examName = res.data.name; //考试名称
          // 报名列表为空 无报名信息
          if (this.examList == "" && this.isSignup == 0) {
            this.tabIndex = 1;
          }
        }
      });
    },

    // 报名考试前信息验证
    onEnroll(e){
      this.timeId = e.timeId
      if(this.loading){return}
      this.loading = true
      this.$ajax({
        url:"/hxclass-pc/exam/signup/list/verifi",
        method: "get",
        params: {
          signupId: e.signupId,
          timeId: e.timeId,
          userId: this.$store.state.userInfo.userId,
        },
      }).then((res) => {
        // 补考需要缴费先予以通过，缴费逻辑在报名信息填写页
        if ((res.code == 200 && res.success) || ((res.message.indexOf("补考") > -1) && res.message.indexOf("不允许") == -1)) {
          this.onOpenSignupModal()
        }
        // 报名限制
        else if (res.code == 500) {
          this.ConfirmBtnText = '我知道了';
          this.isCancelBtnShow = false

          // 未买课
          if (res.message.indexOf('仅限') > -1) {
            this.loading = false
            this.courseList = res.data
            this.isCourseTipsShow = true
            return
          }
          // 未认证
          if (res.message.indexOf('认证') > -1) {
            this.ConfirmBtnText = '立即认证';
            this.isCancelBtnShow = true
          }

          this.tipsText = res.message
          this.isPublicTipsShow = true
        }
        this.loading = false
      });
    },

    // 查询是否买该课
    getIsbuyCourse(){
      this.$ajax({
        url:"/hxclass-pc/course/getCourseByExamConfigId",
        method: "get",
        params: {
          configId: this.configId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.isBuyCourse = res.data ? false : true // 有数据标识未购买，数据为空标识已购课程
          this.courseData = res.data
        }
      });
    },

    // 获取该课程的报名信息
    getSignupList(){
      this.$ajax({
        url:"/hxclass-pc/exam/user/signup-info",
        method: "get",
        params: {
          configId: this.configId,
          userId: this.$store.state.userInfo.userId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(res.data.length){
            // 默认展开第一个考试
            res.data.forEach((element,index) => {
              if(index == 0){
                element.isShow = true
              } else {
                element.isShow = false
              }
            });
          }
          this.myExamList = res.data
        }
      });
    },

    // 更换查看场次
    onSelectTime(e){
      this.signupId = e.signupId
      this.auditTime = e.auditTime
      this.timeData = e.timeList
    },

    // 考试状态
    toolExamStatus(type) {
      // examStatus  考试状态 1未考试/已发卷 2考试中 3已通过 4 未通过
      if (type == 1) {
        return "未考试";
      } else if (type == 2) {
        return "成绩待更新";
      } else if (type == 3) {
        return "已通过";
      } else if (type == 4) {
        return "未通过";
      }
    },

    // 考试时长处理
    manageDuration(con) {
      if (!con) {
        return ''
      }
      let time = "";
      let hour = con.split(":")[0];
      let minute = con.split(":")[1];
      let second = con.split(":")[2];
      if (hour !== "00") {
        if (hour[0] == "0") {
          time += hour.substring(1, hour.length) + "小时";
        } else {
          time += hour + "小时";
        }
      }
      if (minute !== "00") {
        if (minute[0] == "0") {
          time += minute.substring(1, minute.length) + "分钟";
        } else {
          time += minute + "分钟";
        }
        // time += minute+'分钟'
      }
      if (second !== "00") {
        time += second + "秒";
      }
      return time;
    },

    // 修改报名信息
    toMessage(e,dis){
      this.$router.push(
        "/study/registrationMessage?configId=" +
          this.$AES.encode_data(this.configId + "") +
          "&signupId=" +
          this.$AES.encode_data(e.signupId + "") +
          "&timeId=" +
          this.$AES.encode_data(e.timeId + "") +
          "&signupUserId=" +
          this.$AES.encode_data(e.signupUserId + "") +
          "&type=" +
          this.$AES.encode_data(2 + "") +
          "&dis=" +
          this.$AES.encode_data(dis + "") 
      );
    },

    // 修改考试场次
    toChangeExam(signupId,signupUserId){
      this.signupUserId = signupUserId
      this.getModifyExamList(signupId,signupUserId)
    },

    // 考试通过确认信息
    toConfirm(e){
      this.$router.push(
        "/study/signUpConfirmOk?configId=" +
          this.$AES.encode_data(this.configId + "") +
          "&examineeId=" +
          this.$AES.encode_data(e.examineeId + "") +
          "&signupUserId=" +
          this.$AES.encode_data(e.signupUserId + "") 
      );
    },

    toConfirmOk(e){
      this.$router.push(
        "/study/signUpConfirmGroup?configId=" +
          this.$AES.encode_data(e.configId + "") +
          "&examineeId=" +
          this.$AES.encode_data(e.examineeId + "") +
          "&signupUserId=" +
          this.$AES.encode_data(e.signupUserId + "") 
      );
    },

    // 证书信息确认详情
    toConfirmDetail(e){
      this.$router.push(
        "/study/signUpConfirmDetail?signupUserId=" +
          this.$AES.encode_data(e.signupUserId + "")
      );
    },

    // 查询可更换的考试列表
    getModifyExamList(signupId,signupUserId) {
      this.$ajax({
        url: '/hxclass-pc/exam/time/chanage/list?signupId='+signupId+'&signupUserId='+signupUserId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(res.data.length){
            this.examModifyList = res.data
            this.getNowExam(signupUserId)
          } else {
            this.$message.error('暂无可更换的场次');
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 查询当前考试场次
    getNowExam(signupUserId) {
      this.$ajax({
        url: '/hxclass-pc/exam/user_signup/time?signupUserId=' + signupUserId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examObj = res.data[0]
          this.modifyExamShow = true
        } else {
          this.$message.error(res.message);
        }
      })
    },

    // 更换考试场次
    changeSession() {
      let _this = this
      if(!_this.modifyTimeId){
        _this.$message.warning("请选择需要更换的场次");
        return
      }

      _this.$confirm({
        title: '确定更换考试场次吗？',
        okText: '确认',
        onOk() {
          _this.$ajax({
          url: '/hxclass-pc/exam/signup/edit',
            method: "PUT",
            params: {
              signupUserId: _this.signupUserId,
              timeId: _this.modifyTimeId,
            }
          }).then((res) => {
            if (res.code == 200 && res.success) {
              _this.modifyExamShow = false
              _this.$message.success('更换成功');
              _this.getSignupList()
            } else {
              _this.$message.warning(res.message);
            }
          })
        },
        onCancel() {},
      });
    }, 

    onOpenSignupModal(){
      this.visible = true
      this.timer = setInterval(()=>{
        if(this.second <= 0){
          clearInterval(this.timer)
          return
        }
        this.second --
      },1000)
    },
    handleCancel(){
      this.visible = false
    },
    // 关闭报名限制提示窗
    onCloseTipsMadol(){
      // 未买课
      if (this.tipsText.indexOf('仅限') > -1) {
        // this.courseDetail()
      }
      // 未认证
      if (this.tipsText.indexOf('认证') > -1) {
        this.goAuthentication()
      }
      this.isPublicTipsShow = false
    },
    // 课程购买详情
    courseDetail(obj){
      this.$router.push(
        "/knowledgeMall/courseDetail?productId=" +
          this.$AES.encode_data(obj.productId + "") +
          "&code=" +
          obj.productCode
      );
    },
    // 去认证
    goAuthentication(){
      this.$router.push("/personalCenter/identityAuthentication")
    },
    // 报名填写
    onMessagePage(){
      if(this.second <= 0){
        this.$router.push(
          "/study/registrationMessage?configId=" +
            this.$AES.encode_data(this.configId + "") +
            "&signupId=" +
            this.$AES.encode_data(this.signupId + "") +
            "&timeId=" +
            this.$AES.encode_data(this.timeId + "") +
            "&type=" +
            this.$AES.encode_data(1 + "") + 
            "&auditTime=" +
            this.$AES.encode_data(this.auditTime + "")
        );
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.configId = this.$AES.decode_data(this.$route.query.configId);
    this.type = this.$AES.decode_data(this.$route.query.type);
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.getExamList() // 考试列表

    this.getGuide() // 指南列表

    this.getYearDetail() // 全年详情

    this.getIsbuyCourse() // 查询是否买该课

    this.getSignupList() // 获取该课程的报名信息
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    visible(){
      if(!this.visible){
        clearInterval(this.timer)
        setTimeout(()=>{
          this.second = 3
        },500)
      }
    },
    modifyExamShow(){
      if(!this.modifyExamShow)[
        setTimeout(()=>{
          this.modifyTimeId = 0
        },200)
      ]
    },
  }
}
</script>

<style lang="less" scoped>
.menu-inner{
  /deep/ .ant-breadcrumb{
    line-height: 60px;
  }
}
.signup-panel{
  background: #ffffff;
  padding: 30px;
  min-height: 500px;
  border-radius: 5px;
  position: relative;
  .sping{
    width: 20px;
    height: 20px;
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title{
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #15B7DD;
    line-height: 23px;
    &::before{
      content: '';
      display: inline-block;
      width: 3px;
      height: 16px;
      background: #15B7DD;
      border-radius: 2px;
      margin-right: 4px;
      margin-bottom: -1px;
    }
  }

  .content-inner{
    margin-top: 60px;
    .child-item{
      padding-left: 20px;
      border-left: 1px solid rgba(21,183,221,0.3);
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: -7px;
        top: -18px;
        width: 14px;
        height: 14px;
        background: @theme;
        border-radius: 50%;
      }
      .title-icon{
        position: relative;
        bottom: 34px;
        width: 180px;
      }
    }

    // .child-item:last-child{
    //   &::after{
    //     content: '';
    //     position: absolute;
    //     left: -7px;
    //     bottom: -18px;
    //     width: 14px;
    //     height: 14px;
    //     background: @theme;
    //     border-radius: 50%;
    //   }
    // }

    .guide-item{
      position: relative;
      padding-bottom: 70px;
      .guide-ul{
        margin-top: -20px;
        .guide-li{
          // display: flex;
          // align-items: center;
          border-bottom: 1px solid #F2F2F2;
          margin-bottom: 20px;
          padding-bottom: 20px;
          .li-left{
            margin-right: 20px;
            i{
              display: inline-block;
              font-style: normal;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 1px solid #1BB9DE;
              color: #1BB9DE;
              font-size: 14px;
              text-align: center;
              line-height: 18px;
              margin-right: 3px;
            }
          }
          .li-right{
            // flex: 1;
            margin-top: 5px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            >p{
              margin-bottom: 12px;
              img{
                margin-top: -2px;
                width: 12px;
                margin-right: 3px;
              }
            }
            >p:last-child{
              margin-bottom: 0;
            }
          }
        }
        .guide-li:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
      .guide-ul-hide{
        height: 220px;
        overflow: hidden;
      }
      .show-hide{
        position: absolute;
        bottom: 70px;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 40%, #FFFFFF 100%);
        height: 58px;
        text-align: center;
        width: 100%;
        span{
          position: relative;
          top: 35px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 21px;
          cursor: pointer;
        }
        img{
          transform: rotate(180deg);
          width: 13px;
        }
      }
      
    }
    .plan-item{
      margin-top: 22px;
      padding-bottom: 30px;
      .template-1{
        .tabs {
          display: inline-block;
          padding: 0 40px;
          margin-top: -20px;
          margin-bottom: 15px;
          background-color: rgba(21, 183, 221, 0.1);
          .tabItem {
            display: inline-block;
            padding: 0 38px;
            line-height: 38px;
            font-size: 18px;
            color: #666666;
            margin-right: 20px;
            cursor: pointer;
          }
          .tabItem:last-child {
            margin: 0;
          }
          .active {
            color: #ffffff;
            background-color: #15b7dd;
          }
        }

        .plan-inner{
          padding-left: 30px;
          .scroll-time{
            width: 100%;
            overflow-y: hidden;
            overflow-x: scroll;
            white-space: nowrap;
            span{
              display: inline-block;
              margin-right: 30px;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
              cursor: pointer;
            }
            .span-active{
              color: @theme;
              font-weight: 500;
              text-decoration: underline;
            }
          }
          
          /*滚动条样式*/
          .scroll-time::-webkit-scrollbar { //滚动条的宽度
            height: 4px;
          }
          /* 滚动槽 */
          .scroll-time::-webkit-scrollbar-track {
            -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
            border-radius:0;
            background:rgba(255, 255, 255, 0.1);
          }
          /* 滚动条滑块 */
          .scroll-time::-webkit-scrollbar-thumb {
            border-radius:10px;
            background:rgba(0,0,0, .1);
            -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
          }
          
          .div-flex{
            display: flex;
            // margin-top: 20px;
            position: relative;
            .signup-list{
              flex: 1;
              display: flex;
              flex-wrap: wrap;
              .signup-item{
                padding-right: 50px;
                margin-top: 20px;
                margin-right: 50px;
                position: relative;
                .session{
                  font-size: 14px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                  line-height: 21px;
                  margin-bottom: 12px;
                  img{
                    width: 14px;
                    margin-right: 3px;
                    margin-top: -2px;
                  }
                }
                .time{
                  font-size: 12px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 18px;
                  margin-bottom: 7px;
                }
                .all-btn-small{
                  margin-top: 20px;
                  height: 34px;
                  line-height: 34px;
                  width: 112px;
                }
                .disable{
                  background: #C4C4C4;
                  box-shadow: none
                }
              }
              .signup-item:last-child{
                padding-right: 0;
                margin-right: 0;
                &::after{
                  background: #ffffff;
                }
              }
              .signup-item::after{
                content: '';
                position: absolute;
                right: 0;
                top: 50px;
                display: inline-block;
                width: 1px;
                height: 53px;
                background: rgba(0,0,0,0.1);
              }
              .signup-item3:nth-child(3n){
                margin-right: 0;
                padding-right: 0;
                &::after{
                  background: #ffffff;
                }
              }
              .signup-item4:nth-child(4n){
                margin-right: 0;
                padding-right: 0;
                &::after{
                  background: #ffffff;
                }
              }
            }
            .course-shop{
              width: 270px;
              .shop-icon{
                display: block;
                width: 70%;
                margin: 0 auto;
              }
              .product-info-list{
                background: #EDFBFF;
                border-radius: 10px;
                margin-top: 10px;
                padding: 12px;
                .product-info-item{
                  margin-bottom: 18px;
                  position: relative;
                  .product-flex{
                    display: flex;
                    .course-cover{
                      width: 70px;
                      height: 70px;
                      margin-right: 10px;
                      border-radius: 5px;
                    }
                    .course-name{
                      font-size: 14px;
                      font-weight: 400;
                      color: #000000;
                      line-height: 19px;
                      margin-bottom: 10px;
                    }
                    .course-hour{
                      font-size: 12px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #666666;
                      line-height: 18px;
                    }
                  }
                  .buy-btn{
                    text-align: center;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #15B7DD;
                    line-height: 21px;
                    margin-top: 12px;
                    span{
                      line-height: 26px;
                      font-size: 12px;
                      width: 120px;
                      height: 26px;
                      display: inline-block;
                      cursor: pointer;
                      img{
                        width: 11px;
                      }
                    }
                  }
                  .connect-icon{
                    position: absolute;
                    left: -12px;
                    bottom: 0;
                    z-index: 1;
                    width: 21px;
                  }
                }
                .product-info-item::after{
                  content: '';
                  display: inline-block;
                  position: absolute;
                  left: -20px;
                  bottom: 0;
                  width: 27px;
                  height: 27px;
                  background: #EDFBFF;
                  border-radius: 50%;
                }
                .product-info-item:last-child{
                  margin-bottom: 0;
                  &::after{
                    display: none;
                  }
                  .connect-icon{
                    display: none;
                  }
                }
              }
            }
          }
        }

        .year-inner{
          text-align: center;
          img{
            margin-top: 20px;
            width: 600px;
          }
        }
      }
      .template-2{
        background: #F6F6FC;
        border-radius: 10px;
        padding: 16px;
        .exam-plan-item{
          background: #ffffff;
          border-radius: 10px;
          margin-bottom: 16px;
          padding: 16px;
          .head-exam-title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
              font-size: 18px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 27px;
              img{
                width: 17px;
                margin-right: 4px;
                margin-top: -3px;
              }
              .org{
                display: inline-block;
                margin-left: 5px;
                padding: 0 8px;
                background: #D6F7FF;
                border-radius: 6px;
                font-size: 12px;
                color: #025B70;
                line-height: 22px;
                img{
                  width: 14px;
                }
              }
            }
            .open{
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #15B7DD;
              line-height: 21px;
              cursor: pointer;
              img{
                width: 10px;
                margin-left: 4px;
                margin-top: -1px;
              }
              .rote-img{
                transform: rotate(180deg);
              }
            }
          }
          .exam-status-div{
            margin-top: 17px;
            padding-top: 20px;
            border-top: 1px solid rgba(0,0,0,0.1);
            .time-axle{
              display: flex;
              justify-content: space-between;
              position: relative;
              &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 4px;
                top: 8px;
                background: #E9E9EF;
              }
              .axle-item{
                position: relative;
                padding-right: 70px;
                z-index: 1;
                img{
                  width: 20px;
                  height: 20px;
                }
                p{
                  white-space: nowrap;
                  font-size: 12px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 18px;
                }
                .order-pass {
                  color: #15b7dd;
                }

                .order-fail {
                  color: #ea3c31;
                }
              }
            }

            .exam-time{
              margin-top: 20px;
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 18px;
              i{
                font-style: normal;
                color: rgba(0,0,0,0.1);
                margin: 0 10px;
              }
            }
            .sign-btn{
              text-align: right;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 21px;
              margin-top: 16px;
              span{
                margin-left: 24px;
                cursor: pointer;
                img{
                  margin-right: 3px;
                  margin-top: -2px;
                  width: 14px;
                }
              }
            }
          }
        }
        .exam-plan-item:last-child{
          margin-bottom: 0;
        }
      }
    }

    .notice-item{
      margin-top: 23px;
      .h4-title{
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
        position: relative;
        bottom: 26px;
      }
      >div{
        margin-top: -20px;
        P{
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      }
    }
  }
}

.modal_box{
  text-align: center;
  .modal_h4{
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
  }
  .txt-inner{
    text-align: left;
    margin-top: 28px;
    >p{
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
  .sign-btn{
    display: inline-block;
    height: 40px;
    width: 200px;
    text-align: center;
    line-height: 40px;
    color: #FFFFFF;
    background: #15B7DD;
    box-shadow: 0px 4px 4px 0px rgba(49,188,221,0.25);
    border-radius: 20px 20px 20px 20px;
    cursor: pointer;
    i{
      font-style: normal;
    }
  }
  .sign-btn-dis{
    background: rgba(0,0,0,0.2);
    box-shadow: none;

  }
}

.exam-modal-box{
  h4{
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    text-align: center;
  }
  .box{
    margin-top: 24px;
    .title{
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }
    .time-inner{
      margin-top: 16px;
      .time{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 18px;
        img{
          width: 14px;
          margin-top: -2px;
          margin-right: 3px;
        }
      }
      .line{
        margin-top: 8px;
      }
    }
    .select-inner{
      padding: 12px;
      border-radius: 10px;
      border: 1px solid rgba(0,0,0,0.1);
      cursor: pointer;
    }
    .select-active{
      background: @theme;
      border: 1px solid @theme;
      .time{
        color: #ffffff;
      }
    }
  }
  .submit-btn{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .cancel-btn{
      text-align: center;
      border-radius: 23px;
      color: @theme;
      line-height: 34px;
      border: 1px solid @theme;
      width: 114px;
      height: 34px;
      margin-right: 60px;
    }
    span{
      width: 114px;
      font-size: 14px;
      line-height: 34px;
      height: 34px;
      display: inline-block;
      cursor: pointer;
    }
  }
}
.modal-content {
  .tips {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: @theme;
    img {
      margin-right: 7px;
    }
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #333333;
    line-height: 23px;
    margin:  20px;
  }
  .btn-foot{
    display: flex;
    justify-content: center;
  }
}
</style>
